// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardImg {
   padding: var(--spacing);
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid var(--border-color);
   border-radius: var(--box-rounding);
   background-color: var(--background-alt);
   box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
   transition: all 0.2s ease-in-out;
}

.cardImgInner {
   border-radius: var(--box-rounding);
}
`, "",{"version":3,"sources":["webpack://./src/components/CardImg/CardImg.css"],"names":[],"mappings":"AAAA;GACG,uBAAuB;GACvB,aAAa;GACb,uBAAuB;GACvB,mBAAmB;GACnB,qCAAqC;GACrC,kCAAkC;GAClC,uCAAuC;GACvC,uCAAuC;GACvC,gCAAgC;AACnC;;AAEA;GACG,kCAAkC;AACrC","sourcesContent":[".cardImg {\n   padding: var(--spacing);\n   display: flex;\n   justify-content: center;\n   align-items: center;\n   border: 1px solid var(--border-color);\n   border-radius: var(--box-rounding);\n   background-color: var(--background-alt);\n   box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);\n   transition: all 0.2s ease-in-out;\n}\n\n.cardImgInner {\n   border-radius: var(--box-rounding);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
