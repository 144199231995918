// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
   background-color: var(--background-alt);
   display: flex;
   gap: var(--spacing);
   align-items: center;
   justify-content: end;
   font-size: var(--text-sm);
   border: 1px solid var(--border-color-alt);
   border-radius: var(--box-rounding);
   box-shadow: var(--box-shadow);
   margin: var(--spacing);
   height: calc(var(--text-md) + var(--spacing));
}

header a {
   color: var(--success-color);
   transition: color 0.5s;
   padding: var(--spacing-less);
   margin-right: var(--spacing);
}

header a:hover {
   color: var(--hover-color);
   font-size: var(--text-md);
   transition:
      color 0.8s,
      font-size 0.8s;
}

@media screen and (max-width: 660px) {
   header {
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
   }
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;GACG,uCAAuC;GACvC,aAAa;GACb,mBAAmB;GACnB,mBAAmB;GACnB,oBAAoB;GACpB,yBAAyB;GACzB,yCAAyC;GACzC,kCAAkC;GAClC,6BAA6B;GAC7B,sBAAsB;GACtB,6CAA6C;AAChD;;AAEA;GACG,2BAA2B;GAC3B,sBAAsB;GACtB,4BAA4B;GAC5B,4BAA4B;AAC/B;;AAEA;GACG,yBAAyB;GACzB,yBAAyB;GACzB;;oBAEiB;AACpB;;AAEA;GACG;MACG,uBAAuB;MACvB,mBAAmB;MACnB,WAAW;GACd;AACH","sourcesContent":["header {\n   background-color: var(--background-alt);\n   display: flex;\n   gap: var(--spacing);\n   align-items: center;\n   justify-content: end;\n   font-size: var(--text-sm);\n   border: 1px solid var(--border-color-alt);\n   border-radius: var(--box-rounding);\n   box-shadow: var(--box-shadow);\n   margin: var(--spacing);\n   height: calc(var(--text-md) + var(--spacing));\n}\n\nheader a {\n   color: var(--success-color);\n   transition: color 0.5s;\n   padding: var(--spacing-less);\n   margin-right: var(--spacing);\n}\n\nheader a:hover {\n   color: var(--hover-color);\n   font-size: var(--text-md);\n   transition:\n      color 0.8s,\n      font-size 0.8s;\n}\n\n@media screen and (max-width: 660px) {\n   header {\n      justify-content: center;\n      align-items: center;\n      gap: 0.5rem;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
