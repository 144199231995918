// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quote {
   background-color: var(--background-alt);
   display: flex;
   font-style: italic;
   border: 1px solid var(--border-color-alt);
   text-align: center;
   justify-content: center;
   align-items: center;
   border-radius: var(--box-rounding);
   box-shadow: var(--box-shadow);
   width: 100%;
   padding: var(--spacing);
}
`, "",{"version":3,"sources":["webpack://./src/components/Quote/Quote.css"],"names":[],"mappings":"AAAA;GACG,uCAAuC;GACvC,aAAa;GACb,kBAAkB;GAClB,yCAAyC;GACzC,kBAAkB;GAClB,uBAAuB;GACvB,mBAAmB;GACnB,kCAAkC;GAClC,6BAA6B;GAC7B,WAAW;GACX,uBAAuB;AAC1B","sourcesContent":[".quote {\n   background-color: var(--background-alt);\n   display: flex;\n   font-style: italic;\n   border: 1px solid var(--border-color-alt);\n   text-align: center;\n   justify-content: center;\n   align-items: center;\n   border-radius: var(--box-rounding);\n   box-shadow: var(--box-shadow);\n   width: 100%;\n   padding: var(--spacing);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
