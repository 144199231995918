// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding: var(--spacing);
   border: 1px solid var(--border-color);
   border-radius: var(--box-rounding);
   background-color: var(--background-alt);
   box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
   transition: all 0.2s ease-in-out;
   width: 100%;
}

.card .cardInner {
   border-radius: var(--box-rounding);
   margin: var(--spacing) 0;
}

.card .text {
   color: var(--text-color);
}

.card .alt {
   color: var(--text-color-alt);
}
`, "",{"version":3,"sources":["webpack://./src/components/Card/Card.css"],"names":[],"mappings":"AAAA;GACG,aAAa;GACb,sBAAsB;GACtB,uBAAuB;GACvB,uBAAuB;GACvB,qCAAqC;GACrC,kCAAkC;GAClC,uCAAuC;GACvC,uCAAuC;GACvC,gCAAgC;GAChC,WAAW;AACd;;AAEA;GACG,kCAAkC;GAClC,wBAAwB;AAC3B;;AAEA;GACG,wBAAwB;AAC3B;;AAEA;GACG,4BAA4B;AAC/B","sourcesContent":[".card {\n   display: flex;\n   flex-direction: column;\n   justify-content: center;\n   padding: var(--spacing);\n   border: 1px solid var(--border-color);\n   border-radius: var(--box-rounding);\n   background-color: var(--background-alt);\n   box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);\n   transition: all 0.2s ease-in-out;\n   width: 100%;\n}\n\n.card .cardInner {\n   border-radius: var(--box-rounding);\n   margin: var(--spacing) 0;\n}\n\n.card .text {\n   color: var(--text-color);\n}\n\n.card .alt {\n   color: var(--text-color-alt);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
