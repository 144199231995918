// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
   min-width: 300px;
   max-width: 1200px;
   margin: auto;
}

.cards {
   display: flex;
   flex-direction: column;
}

.container {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: var(--spacing);
   margin: var(--spacing);
   font-size: calc(10px + 2vmin);
}

.container .profile-picture {
   pointer-events: none;
}

.container .profile-text {
   max-height: 309px;
   min-height: 309px;
}

@media (max-width: 660px) {
   .container {
      flex-direction: column;
   }

   .container .profile-picture {
      width: 100%;
   }

   .container .profile-text {
      max-width: fit-content;
      max-height: fit-content;
      align-items: center;
      text-align: center;
   }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;GACG,gBAAgB;GAChB,iBAAiB;GACjB,YAAY;AACf;;AAEA;GACG,aAAa;GACb,sBAAsB;AACzB;;AAEA;GACG,aAAa;GACb,uBAAuB;GACvB,mBAAmB;GACnB,mBAAmB;GACnB,sBAAsB;GACtB,6BAA6B;AAChC;;AAEA;GACG,oBAAoB;AACvB;;AAEA;GACG,iBAAiB;GACjB,iBAAiB;AACpB;;AAEA;GACG;MACG,sBAAsB;GACzB;;GAEA;MACG,WAAW;GACd;;GAEA;MACG,sBAAsB;MACtB,uBAAuB;MACvB,mBAAmB;MACnB,kBAAkB;GACrB;AACH","sourcesContent":[".app {\n   min-width: 300px;\n   max-width: 1200px;\n   margin: auto;\n}\n\n.cards {\n   display: flex;\n   flex-direction: column;\n}\n\n.container {\n   display: flex;\n   justify-content: center;\n   align-items: center;\n   gap: var(--spacing);\n   margin: var(--spacing);\n   font-size: calc(10px + 2vmin);\n}\n\n.container .profile-picture {\n   pointer-events: none;\n}\n\n.container .profile-text {\n   max-height: 309px;\n   min-height: 309px;\n}\n\n@media (max-width: 660px) {\n   .container {\n      flex-direction: column;\n   }\n\n   .container .profile-picture {\n      width: 100%;\n   }\n\n   .container .profile-text {\n      max-width: fit-content;\n      max-height: fit-content;\n      align-items: center;\n      text-align: center;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
